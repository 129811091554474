const arrowLookUpRotations: Map<string, string[]> = new Map<string, string[]>([
    //Front
    ["(1,1,1)_(0,0,1)", ["U", "U'", "R", "R'"]],
    ["(0,1,1)_(0,0,1)", ["U", "U'", "M", "M'"]],
    ["(-1,1,1)_(0,0,1)", ["U", "U'", "L'", "L"]],
    ["(1,0,1)_(0,0,1)", ["E", "E'", "R", "R'"]],
    ["(0,0,1)_(0,0,1)", ["E", "E'", "M", "M'"]],
    ["(-1,0,1)_(0,0,1)", ["E", "E'", "L'", "L"]],
    ["(1,-1,1)_(0,0,1)", ["D'", "D", "R", "R'"]],
    ["(0,-1,1)_(0,0,1)", ["D'", "D", "M", "M'"]],
    ["(-1,-1,1)_(0,0,1)", ["D'", "D", "L'", "L"]],

    //Back
    ["(1,1,-1)_(0,0,-1)", ["U'", "U", "R'", "R"]],
    ["(0,1,-1)_(0,0,-1)", ["U'", "U", "M'", "M"]],
    ["(-1,1,-1)_(0,0,-1)", ["U'", "U", "L", "L'"]],
    ["(1,0,-1)_(0,0,-1)", ["E'", "E", "R'", "R"]],
    ["(0,0,-1)_(0,0,-1)", ["E'", "E", "M'", "M"]],
    ["(-1,0,-1)_(0,0,-1)", ["E'", "E", "L", "L'"]],
    ["(1,-1,-1)_(0,0,-1)", ["D", "D'", "R'", "R"]],
    ["(0,-1,-1)_(0,0,-1)", ["D", "D'", "M'", "M"]],
    ["(-1,-1,-1)_(0,0,-1)", ["D", "D'", "L", "L'"]],

    //Wright Side
    ["(1,1,-1)_(1,0,0)", ["B", "B'", "U", "U'"]],
    ["(1,1,0)_(1,0,0)", ["S'", "S", "U", "U'"]],
    ["(1,1,1)_(1,0,0)", ["F'", "F", "U", "U'"]],

    ["(1,0,-1)_(1,0,0)", ["B", "B'", "E", "E'"]],
    ["(1,0,0)_(1,0,0)", ["S'", "S", "E", "E'"]],
    ["(1,0,1)_(1,0,0)", ["F'", "F", "E", "E'"]],

    ["(1,-1,-1)_(1,0,0)", ["B", "B'", "D'", "D"]],
    ["(1,-1,0)_(1,0,0)", ["S'", "S", "D'", "D"]],
    ["(1,-1,1)_(1,0,0)", ["F'", "F", "D'", "D"]],

    //Lest Side
    ["(-1,1,1)_(-1,0,0)", ["F", "F'", "U'", "U"]],
    ["(-1,1,0)_(-1,0,0)", ["S", "S'", "U'", "U"]],
    ["(-1,1,-1)_(-1,0,0)", ["B'", "B", "U'", "U"]],

    ["(-1,0,1)_(-1,0,0)", ["F", "F'", "E'", "E"]],
    ["(-1,0,0)_(-1,0,0)", ["S", "S'", "E'", "E"]],
    ["(-1,0,-1)_(-1,0,0)", ["B'", "B", "E'", "E"]],

    ["(-1,-1,1)_(-1,0,0)", ["F", "F'", "D", "D'"]],
    ["(-1,-1,0)_(-1,0,0)", ["S", "S'", "D", "D'"]],
    ["(-1,-1,-1)_(-1,0,0)", ["B'", "B", "D", "D'"]],

    //Top Side
    ["(1,1,-1)_(0,1,0)", ["B", "B'", "R'", "R"]],
    ["(0,1,-1)_(0,1,0)", ["B", "B'", "M'", "M"]],
    ["(-1,1,-1)_(0,1,0)", ["B", "B'", "L", "L'"]],

    ["(1,1,0)_(0,1,0)", ["S'", "S", "R'", "R"]],
    ["(0,1,0)_(0,1,0)", ["S'", "S", "M'", "M"]],
    ["(-1,1,0)_(0,1,0)", ["S'", "S", "L", "L'"]],

    ["(1,1,1)_(0,1,0)", ["F'", "F", "R'", "R"]],
    ["(0,1,1)_(0,1,0)", ["F'", "F", "M'", "M"]],
    ["(-1,1,1)_(0,1,0)", ["F'", "F", "L", "L'"]],

    //Down Side
    ["(1,-1,1)_(0,-1,0)", ["F", "F'", "R", "R'"]],
    ["(0,-1,1)_(0,-1,0)", ["F", "F'", "M", "M'"]],
    ["(-1,-1,1)_(0,-1,0)", ["F", "F'", "L'", "L"]],

    ["(1,-1,0)_(0,-1,0)", ["S", "S'", "R", "R'"]],
    ["(0,-1,0)_(0,-1,0)", ["S", "S'", "M", "M'"]],
    ["(-1,-1,0)_(0,-1,0)", ["S", "S'", "L'", "L"]],

    ["(1,-1,-1)_(0,-1,0)", ["B'", "B", "R", "R'"]],
    ["(0,-1,-1)_(0,-1,0)", ["B'", "B", "M", "M'"]],
    ["(-1,-1,-1)_(0,-1,0)", ["B'", "B", "L'", "L"]],

]);

export default arrowLookUpRotations;
